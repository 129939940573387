import * as Sentry from '@sentry/nextjs';

Sentry.init({
  beforeSend(event, hint) {
    if (hint.originalException === 'Timeout') return null;
    return event;
  },
  dsn: process.env.NEXT_PUBLIC_SENTRY_DNS,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  enabled:
    process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test',
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
