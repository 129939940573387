import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { appWithTranslation } from 'next-i18next';
import { AppPropsWithLayout } from '@models/common';

import 'antd/dist/antd.css';
import '../styles/app.scss';
import { useRouter } from 'next/router';

const AppLayout = dynamic(() => import('../components/Layout/index'));

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout =
    Component.getLayout ?? ((page) => <AppLayout>{page}</AppLayout>);
  const { asPath, locale } = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const canonicalUrl = (
    process.env.NEXT_PUBLIC_SITE_URL +
    (locale === 'ja' ? '/ja' : '') +
    (asPath === '/' ? '' : asPath)
  )
    ?.split('?')[0]
    ?.split('#')[0];

  const handleLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    window.addEventListener('load', handleLoading);

    return () => {
      window.removeEventListener('load', handleLoading);
    };
  }, []);

  return isLoading ? (
    <div className="fullpage-loading">
      <img src="/images/loading.gif" className="icon" alt="Loading" />
    </div>
  ) : (
    getLayout(
      <>
        <Head>
          <title>
            Leading Vietnam’s Blockchain &amp; AI Development Company - Ekoios
          </title>
          <meta
            name="Description"
            content="We provide excellent IT outsourcing services in developing Blockchain, AI technologies, Web application, Mobile application and RPA solutions."
          />
          {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta
            name="robots"
            content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
          />
          <meta property="og:locale" content="en_GB" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Leading Vietnam’s Blockchain &amp; AI Development Company - Ekoios"
            key="og_title"
          />
          <meta
            property="og:description"
            content="We provide excellent IT outsourcing services in developing Blockchain, AI technologies, Web application, Mobile application and RPA solutions."
            key="og_description"
          />
          <meta property="og:site_name" content="Ekoios" />
          <meta
            property="og:image"
            content={`${process.env.NEXT_PUBLIC_SITE_URL}/images/preview-homepage.jpg`}
            key="og:image"
          />
          <meta
            property="og:image:secure_url"
            content={`${process.env.NEXT_PUBLIC_SITE_URL}/images/preview-homepage.jpg`}
            key="og:image:secure_url"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="628" />
          <meta property="og:image:alt" content="blockchain" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Leading Vietnam’s Blockchain &amp; AI Development Company - Ekoios"
            key="twitter_title"
          />
          <meta
            name="twitter:description"
            content="We provide excellent IT outsourcing services in developing Blockchain, AI technologies, Web application, Mobile application and RPA solutions."
            key="twitter_description"
          />
          <meta
            name="twitter:image"
            content={`${process.env.NEXT_PUBLIC_SITE_URL}/images/preview-homepage.jpg`}
            key="twitter:image"
          />
        </Head>
        <Component {...pageProps} />
      </>,
    )
  );
}

export default appWithTranslation(MyApp);
